* {
  margin: 0px;
  padding: 0px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !important;
}
.MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.barnd_edit_icon svg {
  font-size: 0.8rem !important;
}

.brand_avatar {
  width: 5.3rem !important;
  height: 5.3rem !important;
}

.brand_badge {
  background: white;
  border-radius: 50%;
}
.drawer .MuiPaper-root {
  width: 700px !important;
}
.brand_drawer_close {
  position: absolute !important;
}
.MuiPaper-root {
  box-shadow: none !important;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 10px !important;
}
.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* Scrollbar Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: gray;
}

/* Scrollbar Thumb */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 10px; /* Make the scrollbar thumb rounded */
}

/* Scrollbar Width */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}
.MuiPaper-root {
  border-radius: 0px !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 10px; /* Make the scrollbar thumb rounded */
}

.search-homepage {
  background: black;
  color: white;
  border-radius: 50%;
  padding: 0.5rem;
  width: 3rem !important;
  height: 3rem !important;
  cursor: pointer;
}

.heading-home-featured {
  background: linear-gradient(90deg, #f7e8a8 0%, #f2c56f 50%, #e5b041 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 2rem;
}
.pricetable .MuiDialogContent-root {
  padding: 0px !important;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff; /* Blue color */
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}